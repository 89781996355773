.features-tiles-item-image {
	display: inline-flex;
    border-radius: 50%;
    background-color: #ff0034;
    height: 60px;
    width: 60px;
    justify-content: center;
    align-items: center;
}
.web-img{
	width: 40px;
	height: 40px;
}